
export default {
  name: "Recommend",
  data() {
    return {
      items: [
        {
          title: "企业俱乐部",
          des: [
            `企业家俱乐部采用研讨、交流，集结广大各界企业家智慧，帮助企业家运用更前沿的思维与管理方法，打造企业家互为赋能、共创共赢的圈层平台，为未来发展提供持续动力。`,
          ],
          rules: [],
        },
        {
          title: "城市分会",
          des: [
            `有能力、有精力、有意愿的人，可以在自己当地申请开设天下商邦私享会的城市分会，独立组织各种线上线下的私享会活动。如：`,
          ],
          rules: [
            `1．邀约人参与总会发起的“名人名企私享会“`,
            `2．邀约人参与总会发起的“量商学院”课程`,
            `3．独立组织各种产品的私享会`,
            `4．独立组织天下商邦私享会项目推荐会活动`,
            `5．独立组织盲盒饭局、户外徒步等各类社交娱乐活动`,
            `6．任意一个社群社团（各商会、协会、校友会），成为天下商邦“商会联盟”的成员参与活动`,
          ],
        },
        {
          title: "量子商学院",
          des: [
            `量子商学院将围绕量子思维及管理实践应用，面向不同需求层次的学员开设基础班、专题班、总裁班课程，致力于成为量子管理界的“黄埔军校”。`,
            `未来，量子商学院将成立学员俱乐部。俱乐部将凝聚整合圈层资源、搭建学员学习生活交流平台。俱乐部成员有权参加全年各类管理培训课程。`,
          ],
          rules: [],
        },
      ],
    };
  },
};
