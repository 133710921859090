
export default {
  name: "CommunityHeader",
  props: {
    items: Array,
  },
  data() {
    return {
      title: "进入社区",
      des: `按照不同的兴趣、爱好、人群特征分类，我们社群分为三大类：自然农法宣讲团、蕙芷香遇俱乐部、天下商邦私享会。
      如果您对加入社群感兴趣，您可以通过相应二维码联系工作人员进入。`,
      qrCodeTips: "扫码关注归农读书会",
    };
  },
};
