<template>
  <div id="sq_switch_container">
    <div id="switch_boxs">
      <div
        id="switch_box"
        v-for="(item, index) in items"
        :key="`switch_box_${index}`"
        :style="{ 'background-color': item.bgColor }"
        @mousemove="handleMove(index)"
      >
        <div v-show="selectedIndex !== index" id="sq_switch_title">
          {{ item.title }}
        </div>
        <img
          v-show="selectedIndex === index"
          id="box_img_qr"
          :src="require(`@/assets/community/${item.hoverImg}`)"
        />
      </div>
    </div>
    <div id="detail_container">
      <div id="qr_detail_content">
        <p
          id="qr_detail_des"
          v-for="(des, index) in items[selectedIndex].details"
          :key="`detail_${index}`"
        >
          {{ des }}
        </p>
      </div>
      <Groupbuy v-show="selectedIndex === 2" />
    </div>
  </div>
</template>

<script>
import Groupbuy from "./Groupbuy.vue";

export default {
  name: "Switch",
  components: {
    Groupbuy,
  },
  data() {
    return {
      selectedIndex: 0,
      items: [
        {
          title: "蕙芷香遇\n俱乐部",
          img: "club.png",
          hoverImg: "hzxy_qr.png",
          bgColor: "#8cbbbb",
          details: [
            `以对自然之敬畏与感恩，向植物借力，与植物一起修行！`,
            `蕙芷融合西方芳疗、中医思维、东方智慧，在蕙芷香寓的“寓主”带领下，通过香薰、香灸、按摩推拿、瑜伽、冥想、吐纳、调息……，一起沉浸享受这来自植物的香氛世界。`,
          ],
        },
        {
          title: "自然农法\n社区宣讲团",
          img: "preach.png",
          hoverImg: "zrnf_qr.png",
          bgColor: "#98d7c0",
          details: [
            `归农自然农法社区宣讲团，招募志愿者、义工，倡导自然农法，让我们及后代食用更有力量的食物，让子孙后代居住在更生态的环境。`,
          ],
        },
        // {
        //   title: "天下商邦\n私享会",
        //   img: "groupbuy.png",
        //   hoverImg: "txsb_qr.png",
        //   bgColor: "#da8482",
        //   details: [
        //     `天下商邦是一首歌／一杯酒／一群人`,
        //     `是由多位知名企业家／导演／学者／作家／记者……`,
        //     `联合发起的一个创业者社群`,
        //     `传承发扬中国商邦文化与创业者精神`,
        //     `我们一起温暖前行`,
        //   ],
        // },
      ],
    };
  },
  methods: {
    handleMove(index) {
      if (this.selectedIndex !== index) {
        this.selectedIndex = index;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#sq_switch_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#switch_boxs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
  
}

#switch_box {
  width: 240px;
  height: 240px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#detail_container {
  background-color: #f6f6f6;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sq_switch_title {
  color: #fff;
  font-size: 24px;
  white-space: pre-wrap;
  text-align: center;
}

#box_img_qr {
  padding: 40px;
  width: 160px;
  height: 160px;
}

#qr_detail_content {
  padding: 20px 0 20px 0;
  align-self: center;
  width: 50%;
  text-align: center;
  background-color: #f6f6f6;
}

#qr_detail_des {
  font-size: 10px;
  color: #666666;
}
</style>
