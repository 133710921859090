
import { Options, Vue } from "vue-class-component";
import CommunityHeader from "./Header.vue";
import CommunitySwitch from "./Switch.vue";

@Options({
  components: {
    CommunityHeader,
    CommunitySwitch,
  },
})
export default class Community extends Vue {}
